/*
 * @Author: xin.song
 * @Date: 2020-03-12 17:09:05
 * @Last Modified by: xin.song
 * @Last Modified time: 2024-09-18 15:59:29
 */

// import { api } from './service.js'
import { service } from './service.js'

const server = {
	eget(req) {
		return service.get('/express/eget', req)
	},
	epost(req) {
		return service.post('/express/epost', req)
	},
	erate(req) {
		return service.post('/express/rate', req)
	},

	getNews(req) {
		return service.get(`${process.env.VUE_APP_BASE_API}/tc/a/detail`, req)
	},
	getList(req) {
		return service.get(`${process.env.VUE_APP_BASE_API}/tc/a/summary/get`, req)
	},
	//https://yapi.adsconflux.xyz/project/439/interface/api/1569
}

function download(apk) {
	// // 创建隐藏的可下载链接
	if (apk.includes('www.gbwhatsapp.download')) {
		apk = apk.replace('www.gbwhatsapp.download', 'cdn.gbwhatsapp.download')
	}

	let eleLink = document.createElement('a')
	eleLink.setAttribute('href', apk)

	eleLink.style.display = 'none'
	// eleLink.setAttribute('target', '_blank')
	// 触发点击
	document.body.appendChild(eleLink)
	eleLink.click()
	// 然后移除
	document.body.removeChild(eleLink)
}

function GetQueryString(str) {
	if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
		let reg = new RegExp('(^|&)' + str + '=([^&]*)(&|$)', 'i')
		let r = window.location.search.substr(1).match(reg)
		if (r != null) return unescape(r[2])
	}

	return null
}

function timeAgo(dateString) {
	const now = new Date()
	const past = new Date(dateString)
	const diff = now - past // 毫秒差值

	const seconds = Math.floor(diff / 1000)
	const minutes = Math.floor(seconds / 60)
	const hours = Math.floor(minutes / 60)
	const days = Math.floor(hours / 24)
	const weeks = Math.floor(days / 7)
	const months = Math.floor(days / 30)
	const years = Math.floor(days / 365)

	if (years > 0) {
		return years + ' years ago'
	} else if (months > 0) {
		return months + ' months ago'
	} else if (weeks > 0) {
		return weeks + ' weeks ago'
	} else if (days > 0) {
		return days + ' days ago'
	} else if (hours > 0) {
		return hours + ' hours ago'
	} else if (minutes > 0) {
		return minutes + ' minutes ago'
	} else {
		return seconds + ' seconds ago'
	}
}

function dateStringToTimestamp(dateString) {
	const date = new Date(dateString)
	return date.getTime()
}

function formatDate(oldDate, fmt) {
	// oldDate is 13 digits
	// fmt for formatting, e.g. yyyy-MM-dd hh:mm or MMM for short month name
	// console.log(formatDate(Date.now(), 'yyyy-MM-dd hh:mm')); // 2024-06-25 12:34
	// console.log(formatDate(Date.now(), 'MMM dd, yyyy')) // Jun 25, 2024
	let date = new Date()
	if (typeof oldDate === 'string' || typeof oldDate === 'number') {
		date = new Date(+oldDate)
	} else {
		date = oldDate
	}
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
	}

	const o = {
		'M+': date.getMonth() + 1,
		'd+': date.getDate(),
		'h+': date.getHours(),
		'm+': date.getMinutes(),
		's+': date.getSeconds(),
	}

	const monthsShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

	const padLeftZero = (str) => ('00' + str).substr(str.length)

	Object.keys(o).forEach((k) => {
		if (new RegExp(`(${k})`).test(fmt)) {
			const str = o[k] + ''
			fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
		}
	})

	if (/MMM/.test(fmt)) {
		fmt = fmt.replace('MMM', monthsShort[date.getMonth()])
	}

	return fmt
}

const firebaseConfig = {
	apiKey: 'AIzaSyB2--Z5taGYyPsn3bqhSzM9JQHGkIb4RB8',
	authDomain: 'techcrunchabc-click.firebaseapp.com',
	projectId: 'techcrunchabc-click',
	storageBucket: 'techcrunchabc-click.appspot.com',
	messagingSenderId: '1080475416132',
	appId: '1:1080475416132:web:9e04a6fd65a4c3bec42e11',
	measurementId: 'G-BVWQ96H13C',
}

function roundHalfUp(num, decimalPlaces) {
	if (decimalPlaces === undefined) {
		decimalPlaces = 0 // 默认保留整数位
	}
	const factor = Math.pow(10, decimalPlaces)
	const tempNum = num * factor
	return Math.round(tempNum) / factor
}

// 暴露出这些属性和方法
export default {
	GetQueryString,
	formatDate,
	server,
	download,
	firebaseConfig,
	dateStringToTimestamp,
	timeAgo,
	roundHalfUp,
}
